export const onRouteUpdate = ({ location, prevLocation }) => {
    // console.log('updated route:', location.pathname);
    const search = location.pathname.split('/').slice(0, 3).join('/');
    // const active = document.querySelector('#menuitems a.active')
    // if(active) active.classList.remove('active')

    const nav = document.querySelector('#menuitems a[href="' + search + '/"]');
    if(nav) nav.classList.add('active');

    // use slight delay to ensure that the page title has been set
    setTimeout(() => {
        var _paq = window._paq = window._paq || [];
      _paq.push(['setCustomUrl', window.location.pathname]);
      _paq.push(['setDocumentTitle', document.title]);
      //_paq.push(['setDocumentTitle', '']); // TODO: fix this, need current title
      _paq.push(['requireCookieConsent']);
      _paq.push(['trackPageView']);
      _paq.push(['enableLinkTracking']);

      (function() {
        var u="https://ephoria.matomo.cloud/";
        _paq.push(['setTrackerUrl', u+'matomo.php']);
        _paq.push(['setSiteId', '1']);

        var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
        g.async=true; g.src='https://cdn.matomo.cloud/ephoria.matomo.cloud/matomo.js';
        s.parentNode.insertBefore(g,s);
        //console.log('title->',document.title);
      })();
    }, 100);

  }